import React from 'react';

import { cn } from '@bem-react/classname';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { Dashboard, Reports, Login, Users, Locales, Logout } from '../../pages';

import './Page.css';

export const cnPage = cn('Page');

export const headerRoutes = [
    { link: '/users', name: 'Users' },
    { link: '/dashboard', name: 'Dashboard' },
    { link: '/reports', name: 'Reports' },
    { link: '/locales', name: 'Locales' },
];

export const routes = {
    users: '/users',
    dashboard: '/dashboard',
    reports: '/reports',
    locales: '/locales',
    login: '/login',
    logout: '/logout'
};

export const Page: React.FC = () => {
    return (
        <>
            <div className={cnPage()} id="main-page">
                <Routes>
                    <Route path="/login" element={<Login />} />

                    <Route element={<PrivateRoute />}>
                        <Route path="/users" element={<Users />} />
                        <Route path='/dashboard' element={<Dashboard />} />
                        <Route path='/reports' element={<Reports />} />
                        <Route path='/locales' element={<Locales />} />
                        <Route path="/logout" element={<Logout />} />
                    </Route>
                    <Route path="*" element={<Navigate to={routes.users} />} />
                </Routes>
            </div>
        </>
    );
};
