import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { baseApiUrl, defaultFetchParams } from '../../config'
import { EditLocaleTableRequest, FindLocaleResponse, UpdateLocaleRequest } from '../../types/locale'

export const localeApi = createApi({
    reducerPath: 'locale',
    baseQuery: fetchBaseQuery({
        ...defaultFetchParams,
        baseUrl: `${baseApiUrl}/locale`,
        prepareHeaders: (headers) => {
            const sessionToken = localStorage.getItem('token');
            if (sessionToken) {
                headers.set('x-token', sessionToken);
            }
            return headers;
        }
    }),
    endpoints: build => ({
        findLocales: build.query<FindLocaleResponse[], void>({
            query: () => '',
        }),
        updateLocale: build.mutation<any, EditLocaleTableRequest>({
            query: body => ({
                url: `/${body.id}`,
                method: 'PATCH',
                body: {[body.column]: body.value},
                validateStatus: response => response.status === 200,
            }),
        }),
    }),
});

export const {
    useFindLocalesQuery,
    useUpdateLocaleMutation
} = localeApi;

export const { endpoints, reducerPath, reducer, middleware } = localeApi;
