import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { useUserLogoutQuery } from '../../api/user';

export const Logout: React.FC = () => {
    const { setAuth } = useAuth()
    const navigate = useNavigate();
    const { data: env, isLoading } = useUserLogoutQuery();

    useEffect(() => {
        localStorage.removeItem("token");
        setAuth(false);
        navigate('/login');
    }, [setAuth, navigate, isLoading])

    return (
        <div>Logout</div>
    )
}
