import React, { useCallback } from "react"

import { useGetTableMutation } from '../../api/usersTable';
import { Table } from '../../components';

import { UsersTablePaginationResponse } from '../../types/usersTable';

const loadData = (getUsersData: any) => (setParams: any, params: any) => (data: any) => {
    if (data === undefined && params === undefined)
        return;
    const { skip, limit, filterValue } = data || params;
    
    setParams({ skip, limit, filterValue });
    const requestData: {[id: string]: string} = { 
        skip, limit
    }

    filterValue.forEach((item: {name: string, value?: string}) => {
        if (item.value) {
            requestData[item.name] = item.value;
        }
    })

    return getUsersData(requestData).then((response: {data: UsersTablePaginationResponse}) => {
        const responseData = response.data;
        const {data, total} = responseData;
        return Promise.resolve({ data, count: total });
    })
}


export const Users: React.FC = () => {
    const [getUsersData, { isLoading }] = useGetTableMutation();
    const request = loadData(getUsersData);

    const dataSource = useCallback(request, []);

    return (
        <div style={{width: '100%', height: '100%'}}>
            <Table rows={dataSource} isLoading={isLoading}></Table>
        </div>
    )
}
