import React, { useState, useEffect } from 'react'
import { useFindLocalesQuery } from '../../api/locales'
import { LocaleTable } from '../../components/table/localeTable'
import { FindLocaleResponse } from '../../types/locale'

export const Locales: React.FC = () => {
    const [rowsData, setRowsData] = useState<FindLocaleResponse[]>([]);
    const {data, isLoading} = useFindLocalesQuery();

    useEffect(() => {
        if (!isLoading && data) {
            setRowsData(data);
        }
    }, [data, isLoading])

    return (
        <div style={{width: '100%', height: '100%'}}>
            <LocaleTable rows={rowsData} isLoading={isLoading} setRows={setRowsData}></LocaleTable>
        </div>
    )
}
