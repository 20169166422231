import React, { useEffect, useCallback, useState, useMemo } from 'react';

import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';

import { useEditTableMutation } from '../../api/usersTable';

import '@inovua/reactdatagrid-community/index.css';

const filterValue = [
    { name: 'id', operator: 'contains', type: 'string', value: '' },
    { name: 'username', operator: 'contains', type: 'string', value: '' },
    { name: 'email', operator: 'contains', type: 'string', value: '' },
];

const columns = [
    { name: 'id', header: 'Telegram Id', defaultFlex: 1, editable: false},
    { name: 'username', header: 'Username', defaultFlex: 1, editable: false},
    { name: 'email', header: 'Email', defaultFlex: 1, editable: false},
    { name: 'chat_model', header: 'Chat Model', defaultFlex: 1, editable: false},
    { name: 'image_model', header: 'Image Model', defaultFlex: 1, editable: false},
    { name: 'recId', header: 'RecId', defaultFlex: 1, editable: true},
    { name: 'plan_id', header: 'Plan Id', defaultFlex: 1, editable: true},
    { name: 'mj_generation', header: 'MJ Generation', defaultFlex: 1, editable: true},
    { name: 'gpt4_generation', header: 'GPT4 Generation', defaultFlex: 1, editable: true},
    {
        name: 'timeToReset',
        header: 'Time To Reset',
        defaultFlex: 1,
        editable: true,
        render: ({ value }: any) => {
            return moment(value).format('hh:mm:ss DD-MM-YYYY')
        }
    },
    {
        name: 'timeExpiredSub',
        header: 'Time Expired Sub',
        defaultFlex: 1,
        editable: true,
        render: ({ value }: any) => {
            return moment(value).format('hh:mm:ss DD-MM-YYYY')
        }
    },
    { name: 'utm', header: 'UTM', defaultFlex: 1, editable: false},
];

type TableProps = {
    rows: any;
    isLoading: boolean;
}

const gridStyle = { minHeight: "calc(100vh - 4rem)" }

export const Table: React.FC<TableProps> = props => {
    const [params, setParams] = useState(undefined);
    const [editData, { isLoading: isUpdating, data }] = useEditTableMutation();

    const onEditComplete = useCallback((data: any) => {
        const { data: { id }, columnId: column, value } = data;
        editData({id, column, value});
    }, []);

    const rows = useMemo(() => props.rows(setParams, params), [data]);

    useEffect(() => {
        if (data?.message === 'Success'){
            rows();
        }
    }, [data]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ReactDataGrid
                onEditComplete={onEditComplete}
                editable
                style={gridStyle}
                idProperty="id"
                dataSource={rows}
                columns={columns}
                defaultFilterValue={filterValue}
                pagination
                defaultLimit={20}
                sortable={false}
                loading={props.isLoading || isUpdating}
            />
        </div>
    );
};
