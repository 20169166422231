type Environment = {
    apiUrl: string;
}

interface WindowWithEnv {
    adminEnvironment: Environment;
}

export const adminEnv = (window as unknown as WindowWithEnv).adminEnvironment;

export const baseApiUrl = adminEnv.apiUrl;

export const defaultFetchParams = {
    credentials: 'include' as RequestCredentials,
    headers: { 'Content-Type': 'application/json' },
};
