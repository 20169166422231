import React from 'react';

import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

import { store } from './store';
import { Root } from './components';
import { AuthProvider } from './context/AuthProvider';

import './index.css';

const domNode = document.getElementById('root') as HTMLElement;
const root = createRoot(
    domNode,
);

root.render(
    <React.StrictMode>
        <AuthProvider>
            <Provider store={store}>
                <Root />
            </Provider>
        </AuthProvider>
    </React.StrictMode>,
);
