import React, { useEffect } from "react"

import { useLocation, useNavigate } from 'react-router-dom';

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { useUserLoginMutation } from '../../api/user';
import useAuth from '../../hooks/useAuth';
import type { UserLogin } from '../../types/user';

export const Login: React.FC = () => {
    const { isAuthenticated, setAuth } = useAuth();
    const [userLogin, { isLoading: isUpdating, data: userInfo }] = useUserLoginMutation();

    const navigate = useNavigate();
    const location = useLocation();

    const { from } = location.state || { from: { pathname: "/" } };

    useEffect(() => {
        if(userInfo && userInfo.token) {
            localStorage.setItem("token", userInfo.token)
            setAuth(true);
            navigate(from.pathname, { replace: true });
        }

    }, [userInfo]);


    useEffect(() => {
        if (isAuthenticated) {
            navigate(from.pathname, { replace: true });
        }
    }, [isAuthenticated]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const loginData = {
            email: data.get('email'),
            password: data.get('password')
        } as UserLogin;

        userLogin(loginData);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{  
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        disabled={isUpdating}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        disabled={isUpdating}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isUpdating}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
