import { configureStore } from '@reduxjs/toolkit';

import { userApi } from '../api/user';
import { usersTableApi } from '../api/usersTable';
import { reportApi } from '../api/report';
import { localeApi } from '../api/locales';

export const store = configureStore({
    reducer: {
        [userApi.reducerPath]: userApi.reducer,
        [usersTableApi.reducerPath]: usersTableApi.reducer,
        [reportApi.reducerPath]: reportApi.reducer,
        [localeApi.reducerPath]: localeApi.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            userApi.middleware,
            usersTableApi.middleware,
            reportApi.middleware,
            localeApi.middleware,
        ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
