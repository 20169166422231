import React, { useMemo, useCallback, useEffect, useState } from "react";

import { useSearchParams, SetURLSearchParams} from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import StartIcon from '@mui/icons-material/Start';
import Button from '@mui/material/Button';
import ImageIcon from '@mui/icons-material/Image';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import ReactQuill from 'react-quill';

import useInterval from '@use-it/interval';

import {
    useGetReportByIdMutation,
    useGetReportsListMutation,
    useUpdateReportMutation,
    useCreateReportMutation,
    useStartProdMutation,
    useStartTestMutation
} from '../../api/report';

import type { Report, NewReportRequest } from '../../types/report';

import 'react-quill/dist/quill.snow.css';

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'link'],
    [],
    []
];

interface ReportsHistoryItemProps {
    title: string;
    status: string;
    id: number;
    onClick: () => void;
}


const ReportsHistoryItem: React.FC<ReportsHistoryItemProps> = props => {

    return (
        <div onClick={props.onClick} style={{display: "flex", border: "1px solid #CCC", padding: "10px", justifyContent: "space-between", cursor: "pointer", margin: "5px 0", borderRadius: "10px"}}>
            <h3>Report: #{props.id}</h3>
            <h4>{props.status}</h4>
        </div>
    );
}

interface ReportsHistoryProps {
    changeParams: (id: string | null) => void;
    data: Report[] | undefined;
}

const ReportsHistory: React.FC<ReportsHistoryProps> = (props) => {
    const changeParams = useCallback((id: number | null) => () => {
        props.changeParams(id ? id.toString() : null);
    }, [props]);

    return (
        <div style={{display: "flex", flexDirection: "column", minWidth: "350px", height: "calc(100vh - 4rem)", overflowY: "scroll", padding: "25px" }}>
            <Button variant="outlined" style={{marginBottom: "5px"}} onClick={changeParams(null)}>+ CREATE NEW</Button>
            {props.data && props.data.map(({title, status, id}) => 
                <ReportsHistoryItem title={title} status={status} id={id} key={id} onClick={changeParams(id)} />
            )}
        </div>
    )
}

interface CurrentReportDataProps {
    reportId: string;
}

interface NewReportProps {
    changeParams: (id: string | null) => void;
}

interface ReportDataProps {
    data?: Report | NewReportRequest;
    updateData: any;
    isNew?: boolean;
    isUpdating: boolean;
    onSave: any;
    onStartTest?: any;
    onStartProd?: any;
}


const ReportData: React.FC<ReportDataProps> = (props) => {
    const { data, isNew, isUpdating, updateData } = props;

    const [isChanged, setIsChanged] = React.useState(false);

    const isTest = ['START_TEST', 'PENDING_TEST'].includes(data?.status || '');
    const isProd = ['START_PROD', 'PENDING_PROD'].includes(data?.status || '');
    const isDisabled = isTest || isProd

    const onUploadImage = useCallback((event: { target: { files: any; }; }) => {
        const fr = new FileReader();
        fr.onload = function () {
            updateData((oldData: any) => ({
                ...oldData,
                "image": fr.result
            }));
            setIsChanged(true);
        }
        fr.readAsDataURL(event.target.files[0]);
    }, []);

    const onUploadFile = useCallback((event: { target: { files: any; }; }) => {
        const fr = new FileReader();
        fr.onload = function () {
            updateData((oldData: any) => ({
                ...oldData,
                "userListCsvBase64": fr.result
            }));
            setIsChanged(true);
        }
        fr.readAsDataURL(event.target.files[0]);
    }, []);

    const onDeleteFile = useCallback(() => {
        updateData((oldData: any) => ({
            ...oldData,
            "userListCsvBase64": ""
        }));
        setIsChanged(true);
    }, []);

    const onDeleteImage = useCallback(() => {
        updateData((oldData: any) => ({
            ...oldData,
            "image": ""
        }));
        setIsChanged(true);
    }, []);

    const onChange = useCallback((field: string) => (event: any) => {
        updateData((oldData: any) => ({
            ...oldData,
            [field]: (typeof event === 'string' ? event : event.target.value)
        }));
        setIsChanged(true);
    }, []);

    const onSave = useCallback(() => {
        props.onSave();
        setIsChanged(false);
    }, [props]);

    return (
        <div style={{ width: "100%", padding: "20px 30px", height: "calc(100vh - 4rem)", overflowY: "scroll" }}>
            <h1 style={{fontSize: "25px", fontWeight: "bold"}}>{isNew ? "New report" : `Report #${data?.id || 0} - ${data?.status || ''}`}</h1>
            {!isNew && ['TESTED', 'SUCCESS'].includes(data?.status || '') && 
                <h1 style={{fontSize: "20px", fontWeight: "bold"}}>Last report - success: {data?.successCount}, error: {data?.errorCount}</h1>
            }
            <TextField
                style={{ margin: "20px 0" }}
                label="Title"
                variant="outlined"
                value={data?.title || ''}
                disabled={!isNew || isDisabled}
                placeholder="Enter title of report..."
                onChange={onChange("title")}
            />
            <div style={{marginBottom: "20px", display: 'flex', flexDirection: 'row'}} >   
                <Button
                    variant="outlined"
                    startIcon={!Boolean(data?.userListCsvBase64) ? <UploadFileIcon /> : <DeleteIcon />}
                    component="label"
                    color={!Boolean(data?.userListCsvBase64) ? "primary" : "error" }
                    disabled={isUpdating || isDisabled}
                    onClick={!Boolean(data?.userListCsvBase64) ? () => {} : onDeleteFile}

                >
                    {!Boolean(data?.userListCsvBase64) ? (
                        <>
                            Add users list
                            <input hidden accept=".csv" type="file" onChange={onUploadFile} disabled={isUpdating} />
                        </>
                    ): "Remove users list"}
                </Button>
            </div>
            

            <div style={{ width: "325px", marginBottom: "20px", height: "375px", display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between"}}>
                <div style={{ width: "325px", height: "325px", border: "1px solid #ccc"}}>
                    {Boolean(data?.image) && <img style={{ width: "100%", height: "100%"}} src={data?.image || ''} />}
                </div>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                    <Button
                        variant="outlined"
                        startIcon={<ImageIcon />}
                        component="label"
                        disabled={isUpdating || Boolean(data?.image) || isDisabled}
                    >
                        Add
                        <input hidden accept="*" type="file" onChange={onUploadImage} disabled={isUpdating} />
                    </Button>
                    <Button
                        variant="outlined"
                        endIcon={<DeleteIcon />}
                        component="label"
                        disabled={isUpdating || !Boolean(data?.image) || isDisabled}
                        color="error"
                        onClick={onDeleteImage}
                    >
                        Remove
                    </Button>
                </div>
            </div>
            
            <ReactQuill
                theme="snow"
                value={data?.message || ''}
                onChange={onChange("message")}
                modules={{ toolbar: toolbarOptions }}
                readOnly={isUpdating || isDisabled}
            />

            <div style={{marginTop: "20px", display: 'flex', flexDirection: 'row'}} >   
                <LoadingButton
                    style={{marginRight: "20px"}}
                    onClick={onSave}
                    loading={isUpdating}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    disabled={!isChanged || isDisabled || !data?.title || !data?.message || !data?.image}
                >
                    <span>Save</span>
                </LoadingButton>
                {!isNew && (
                    <>
                        <LoadingButton
                            color="success"
                            style={{marginRight: "20px"}}
                            onClick={props.onStartTest}
                            loading={isUpdating || isTest}
                            loadingPosition="start"
                            startIcon={<StartIcon />}
                            variant="contained"
                            disabled={isChanged}
                        >
                            <span>{ isTest ? `Sending success: ${data?.successCount || '0'}, error: ${data?.errorCount || '0'}` : "Start Test" }</span>
                        </LoadingButton>
                        <LoadingButton
                            color="success"
                            style={{marginRight: "20px"}}
                            onClick={props.onStartProd}
                            loading={isUpdating || isProd}
                            loadingPosition="start"
                            startIcon={<StartIcon />}
                            variant="contained"
                            disabled={isChanged || !['TESTED', 'SUCCESS'].includes(data?.status || '')}
                        >
                            <span>{ isProd ? `Sending success: ${data?.successCount || '0'}, error: ${data?.errorCount || '0'}` : "Start Prod" }</span>
                        </LoadingButton>
                    </>
                )}
            </div>
        </div>
    );
}


const NewReport: React.FC<NewReportProps> = (props) => {
    const [saveFunc, { isLoading: isUpdating, data: reportData }] = useCreateReportMutation();
    const [currentData, updateData] = useState<NewReportRequest>({
        image: "",
        title: "",
        message: "",
        userListCsvBase64: null,
    });

    const onSave = useCallback(() => {
        saveFunc(currentData);
    }, [currentData]);

    useEffect(() => {
        if (reportData) {
            props.changeParams(reportData.id.toString());
        };
    }, [reportData]);

    return <ReportData isNew data={currentData} updateData={updateData} isUpdating={isUpdating} onSave={onSave} />;
};


const CurrentReportData: React.FC<CurrentReportDataProps> = (props) => {
    const { reportId } = props;

    const [getReport, { isLoading: isLoadingReport, data: reportData }] = useGetReportByIdMutation();
    const [saveFunc, { isLoading: isUpdatingReport, data: updatedReportData }] = useUpdateReportMutation();
    const [startTestFunc, { isLoading: isStartingTest, data: testReportData }] = useStartTestMutation();
    const [startProdFunc, { isLoading: isStartingProd, data: prodReportData }] = useStartProdMutation();

    const [data, updateData] = useState(reportData);

    useInterval(() => {
        if (['START_PROD', 'PENDING_PROD', 'START_TEST', 'PENDING_TEST'].includes(data?.status || '')) {
            getReport({ reportId });
        }
    }, 1000 * 1);

    const onSave = useCallback(() => {
        if (data) {
            saveFunc(data);
        }
    }, [data]);

    const onStartTest = useCallback(() => {
        if (data) {
            startTestFunc({reportId: data.id.toString()});
        }
    }, [data]);

    const onStartProd = useCallback(() => {
        if (data) {
            startProdFunc({reportId: data.id.toString()});
        }
    }, [data]);

    useEffect(() => {
        getReport({ reportId });
    }, [reportId]);

    useEffect(() => {
        if (reportData) {
            updateData(reportData);
        }
    }, [reportData])

    useEffect(() => {
        if (updatedReportData) {
            updateData(updatedReportData);
        }
    }, [updatedReportData]);

    useEffect(() => {
        if (testReportData) {
            updateData(testReportData);
        }
    }, [testReportData]);

    useEffect(() => {
        if (prodReportData) {
            updateData(prodReportData);
        }
    }, [prodReportData]);
    
    return <ReportData
                data={data}
                updateData={updateData}
                isUpdating={isUpdatingReport || isStartingTest || isStartingProd}
                onSave={onSave}
                onStartProd={onStartProd}
                onStartTest={onStartTest}
            />;    
}

export const Reports: React.FC = () => {
    const [loadFunc, { data: reportsList }] = useGetReportsListMutation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currrentId, setCurrentId] = useState(searchParams.get('report_id'));

    const data = useMemo(() => reportsList, [reportsList]);

    const updateCurrentId = useCallback((newId: string | null) => {
        setCurrentId(newId);
        setSearchParams(newId ? {report_id: newId} : {});
    }, []);

    useEffect(() => {
        loadFunc();
    }, []);

    useInterval(() => {
        loadFunc();
    }, 1000 * 5);

    return (
        <div style={{ display: "flex", flexDirection: "row", position: "relative", width: "100%" }}>
            <ReportsHistory changeParams={updateCurrentId} data={data} />
            {currrentId && <CurrentReportData reportId={currrentId} />}
            {!currrentId && <NewReport changeParams={updateCurrentId} />}
        </div>
    );
}
