import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseApiUrl, defaultFetchParams } from '../../config';

import { UsersTablePaginationResponse, UsersTableRequest } from '../../types/usersTable';
import { EditTableRequest } from '../../types/usersTable';

export const usersTableApi = createApi({
    reducerPath: 'usersTable',
    baseQuery: fetchBaseQuery({ 
        ...defaultFetchParams, 
        baseUrl: `${baseApiUrl}/users`,
        prepareHeaders: (headers) => {
            const sessionToken = localStorage.getItem('token');
            if (sessionToken) {
                headers.set('x-token', sessionToken);
            }
            return headers;
        }        
    }),

    endpoints: build => ({
        getTable: build.mutation<UsersTablePaginationResponse, UsersTableRequest>({
            query: (args) => {
                return {
                    url: '',
                    params: args,
                };
            },
        }),
        editTable: build.mutation<any, EditTableRequest>({
            query: body => ({
                url: `/${body.id}`,
                method: 'PUT',
                body: {[body.column]: body.value},
                validateStatus: response => response.status === 200,
            }),
        }),
    }),
});

// Auto-generated hooks
export const { 
    useGetTableMutation,
    useEditTableMutation
} = usersTableApi;

// Possible exports
export const { endpoints, reducerPath, reducer, middleware } = usersTableApi;
