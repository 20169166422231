import React, { useCallback } from 'react';

import { cn } from '@bem-react/classname';
import { BrowserRouter } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';

import { Page, headerRoutes, Header } from '../../components';

import './Root.css';

export const cnRoot = cn('Root');

export const Root: React.FC = () => {
    return (
        <NextUIProvider>
            <BrowserRouter>
                <Header routes={headerRoutes} />
                <Page />
            </BrowserRouter>
        </NextUIProvider>
    );
};
