import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseApiUrl, defaultFetchParams } from '../../config';
import type { Report, GetReportRequest, NewReportRequest } from '../../types/report';

export const reportApi = createApi({
    reducerPath: 'report',
    baseQuery: fetchBaseQuery({ 
        ...defaultFetchParams, 
        baseUrl: `${baseApiUrl}/reports`,
        prepareHeaders: (headers) => {
            const sessionToken = localStorage.getItem('token');
            if (sessionToken) {
                headers.set('x-token', sessionToken);
            }
            return headers;
        }        
    }),
    endpoints: build => ({
        getReportsList: build.mutation<Report[], void>({
            query: () => ({
                url: '/',
                method: 'GET',
                validateStatus: response => response.status === 200,
            }),
        }),
        getReportById: build.mutation<Report, GetReportRequest>({
            query: (args) => {
                return {
                    url: `/${args.reportId}`,
                    method: 'GET',
                };
            },
        }),
        createReport: build.mutation<Report, NewReportRequest>({
            query: body => ({
                url: '/',
                method: 'POST',
                body: body,
                validateStatus: response => response.status === 200,
            }),
        }),
        updateReport: build.mutation<Report, Report>({
            query: body => ({
                url: `/${body.id}`,
                method: 'PUT',
                body: body,
                validateStatus: response => response.status === 200,
            }),
        }),
        startTest: build.mutation<Report, GetReportRequest>({
            query: (args) => {
                return {
                    url: `/${args.reportId}/start-test`,
                    method: 'PATCH',
                    params: args,
                };
            },
        }),
        startProd: build.mutation<Report, GetReportRequest>({
            query: (args) => {
                return {
                    url: `/${args.reportId}/start-prod`,
                    method: 'PATCH',
                };
            },
        }),
    }),
});

// Auto-generated hooks
export const { 
    useGetReportsListMutation,
    useGetReportByIdMutation,
    useUpdateReportMutation,
    useCreateReportMutation,
    useStartTestMutation,
    useStartProdMutation,
} = reportApi;

// Possible exports
export const { endpoints, reducerPath, reducer, middleware } = reportApi;
