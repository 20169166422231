import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseApiUrl, defaultFetchParams } from '../../config';
import type { User, UserLogin } from '../../types/user';

export const userApi = createApi({
    reducerPath: 'user',
    baseQuery: fetchBaseQuery({ 
        ...defaultFetchParams, 
        baseUrl: `${baseApiUrl}/`,
        prepareHeaders: (headers) => {
            const sessionToken = localStorage.getItem('token');
            if (sessionToken) {
                headers.set('x-token', sessionToken);
            }
            return headers;
        }        
    }),
    endpoints: build => ({
        userLogin: build.mutation<User, UserLogin>({
            query: body => ({
                url: 'auth',
                method: 'POST',
                body: body,
                validateStatus: response => response.status === 200,
            }),
        }),
        userLogout: build.query<string, void>({
            query: () => 'logout',
        }),
    }),
});

// Auto-generated hooks
export const { 
    useUserLoginMutation,
    useUserLogoutQuery
} = userApi;

// Possible exports
export const { endpoints, reducerPath, reducer, middleware } = userApi;
