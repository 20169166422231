import React, { createContext, useState, useEffect } from "react";

// Определяем тип контекста
type AuthContextType = {
    isAuthenticated: boolean; // флаг, показывающий, аутентифицирован ли пользователь
    setAuth: (auth: boolean) => void; // функция для изменения значения isAuthenticated
};

// Создаем контекст с типом AuthContextType и начальными значениями по умолчанию
const AuthContext = createContext<AuthContextType>({
    isAuthenticated: false,
    setAuth: () => { },
});

// Создаем компонент провайдера, который предоставляет данные контекста всем дочерним компонентам
export const AuthProvider = ({ children }: { children: JSX.Element }) => {
    // Используем хук useState для создания переменной isAuthenticated и функции setAuth для ее изменения
    const sessionToken = localStorage.getItem('token');
    const data = (sessionToken ?
        JSON.parse(atob(sessionToken.split(".")[1]).toString()) as any :
        {auth: false}
    )

    const [isAuthenticated, setAuth] = useState<boolean>(data && data.auth);

    useEffect(() => {
        if (isAuthenticated !== data && data.auth) {
            setAuth(data && data.auth);
        }
    }, [data]);
    
    // Возвращаем контекст провайдера, передавая значения isAuthenticated и setAuth в качестве значения контекста
    return (
        <AuthContext.Provider value={{ isAuthenticated, setAuth }}>
        {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
