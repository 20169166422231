import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'

import '@inovua/reactdatagrid-community/index.css'
import { useUpdateLocaleMutation } from '../../api/locales'
import { FindLocaleResponse } from '../../types/locale'

const filterValue = [
    { name: 'key', operator: 'contains', type: 'string', value: '' },
    { name: 'ruText', operator: 'contains', type: 'string', value: '' },
    { name: 'enText', operator: 'contains', type: 'string', value: '' },
    { name: 'description', operator: 'contains', type: 'string', value: '' },
];

const columns = [
    { name: 'key', header: 'Key', defaultFlex: 1, editable: false},
    { name: 'ruText', header: 'RuText', defaultFlex: 1, editable: true},
    { name: 'enText', header: 'EnText', defaultFlex: 1, editable: true},
    { name: 'description', header: 'Description', defaultFlex: 1, editable: true},
];

interface LocaleTableProps {
    rows: FindLocaleResponse[];
    isLoading: boolean;
    setRows: React.Dispatch<React.SetStateAction<FindLocaleResponse[]>>;
}

const gridStyle = { minHeight: "calc(100vh - 4rem)" }

export const LocaleTable: React.FC<LocaleTableProps> = props => {
    const [editData, { isLoading: isUpdating, data }] = useUpdateLocaleMutation();

    const onEditComplete = useCallback((data: any) => {
        const { data: { key: id }, columnId: column, value } = data;
        editData({id, column, value});
    }, []);

    useEffect(() => {
        if (!isUpdating && data) {
            props.setRows((old) => {
                return old.map((row) => {
                    if (row.key === data.key) {
                        return data;
                    }
                    return row;
                });
            });
        }
    }, [data,isUpdating]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ReactDataGrid
                onEditComplete={onEditComplete}
                editable={!isUpdating}
                style={gridStyle}
                idProperty="key"
                dataSource={props.rows}
                columns={columns}
                defaultFilterValue={filterValue}
                sortable={true}
                loading={props.isLoading}
            />
        </div>
    );
};
